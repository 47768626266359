import { graphql, Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"

import Navbar from "../components/Menu/LifestyleNav"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Button from "@bit/azheng.joshua-tree.button/Button"
import NuvoImage from "../components/NuvoImage"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { CallToAction } from "./lifestyle"
import ImageMeta from "../components/ImageMeta"
import { PrivacyFooter } from "./lifestyle"

import { wrapSup } from "../utils/utils"

const LifestyleDentalImplantsPage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    isRobotsIndex,
    hero,
    sections,
    callToAction
  } = data.allUniquePagesJson.nodes[0]

  const patients = data.patients.nodes
  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        robots={!isRobotsIndex ? "noindex" : ""}
      />
      <Navbar location={location.pathname} />
      <div className="lifestyle ls__di">
        <NuvoImage
          useAR
          cloudName="nuvolum"
          publicId={hero.image}
          width="auto"
          responsive
        />

        <div className="ls__section ls__di-hero">
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
              <h1>{hero.heading}</h1>
              <h2>{hero.subheading}</h2>
            </div>
            <div className="column is-2"></div>
            <div className="columns is-narraow">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${hero.youtube.code}`}
                controls
                playing
                buttonClass="contained ls__theater-btn"
              />
            </div>
            <div className="column is-2"></div>
          </div>
        </div>

        {sections.map((sect, i) => {
          if (sect.type === "text") {
            return (
              <div className="ls__section" key={i}>
                <div className="columns">
                  <div className="column is-5"></div>
                  <div className="column joshua-tree-content">
                    <MarkdownViewer markdown={sect.content} />
                  </div>
                  <div className="column is-5"></div>
                </div>
              </div>
            )
          }

          if (sect.type === "imageText") {
            return (
              <div className="ls__section" key={i}>
                <div className="columns">
                  <div className="column is-3"></div>
                  <div className="column">
                    <h3 className="mb-2">{sect.heading}</h3>
                  </div>
                  <div className="column is-3"></div>
                </div>
                <div className="columns">
                  <div className="column is-3"></div>
                  <div className="column">
                    <ImageMeta
                      className="mt-2"
                      cloudName="nuvolum"
                      publicId={sect.image}
                    />
                    <MarkdownViewer className="mt-2" markdown={sect.blurb} />
                    <div
                      className="px-40-mobile"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        buttonText={sect.button.buttonText}
                        href={sect.button.href}
                        destination={sect.button.destination}
                        className="mx-0"
                        contained={sect.button.appearance === "contained"}
                      />
                    </div>
                  </div>
                  <div className="column is-3"></div>
                </div>
              </div>
            )
          }

          if (sect.type === "featuredList") {
            return (
              <div
                className="ls__section ls__di-featured-list color-back"
                key={i}
              >
                <div className="columns ls__featured-list">
                  <div className="column is-5"></div>
                  <div className="column">
                    <h3 className="h2-style">{sect.heading}</h3>
                    {sect.items.map((item, i) => (
                      <div className="ls__featured-list-item" key={i}>
                        <span>{i + 1}</span>
                        <MarkdownViewer markdown={item} />
                      </div>
                    ))}
                  </div>
                  <div className="column is-5"></div>
                </div>
              </div>
            )
          }

          if (sect.type === "patients") {
            return (
              <div className="ls__section color-back" key={i}>
                <div className="columns">
                  <div className="column is-3"></div>
                  <div className="column">
                    <h3 className="has-text-centered">{sect.heading}</h3>

                    <div className="ls__di-patient-wall">
                      {patients.map((p, i) => (
                        <div key={i} className="ls__facewall-card">
                          <ImageMeta
                            cloudName="nuvolum"
                            publicId={p.thumbnailPublicId}
                            width="auto"
                            responsive
                          />

                          <h6>{`Meet ${p.reviewerName}`}</h6>
                          <h6
                            dangerouslySetInnerHTML={{
                              __html: wrapSup(p.mainProcedureDone)
                            }}
                          />

                          <TheaterVideo
                            videoUrl={`https://www.youtube.com/watch?v=${p.youtube}`}
                            controls
                            playing
                            buttonClass="ls__theater-btn"
                          />
                        </div>
                      ))}
                    </div>
                    {/* <div
                      className="px-40-mobile"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        buttonText={sect.button.buttonText}
                        href={sect.button.href}
                        destination={sect.button.destination}
                        className="mx-0"
                        contained={sect.button.appearance === "contained"}
                      />
                    </div> */}
                  </div>
                  <div className="column is-3"></div>
                </div>
              </div>
            )
          }

          return <></>
        })}

        <CallToAction
          heading={callToAction.heading}
          blurb={callToAction.blurb}
          buttons={callToAction.buttons}
          centeredBlurb
        />
      </div>

      <PrivacyFooter />
    </>
  )
}

export const lifestyleDentalImplantsPageQuery = graphql`
  query lifestyleDentalImplantsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        isRobotsIndex
        hero {
          image
          heading
          subheading
          youtube {
            code
          }
        }
        sections {
          type
          content
          heading
          blurb
          items
          image
          button {
            buttonText
            appearance
            href
            destination
          }
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              href
              appearance
              buttonText
              destination
            }
          }
        }
      }
    }
    patients: allReviewsJson(
      filter: { mainProcedureDone: { in: ["Full-Arch Restoration"] } }
      limit: 4
    ) {
      nodes {
        reviewerName
        youtube
        thumbnailPublicId
        mainProcedureDone
      }
    }
  }
`

export default LifestyleDentalImplantsPage
